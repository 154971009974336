import { TaskInfo } from './TaskInfo';

export interface PendingVncTask {
  display: number;
  userId: string;
  url: string;
  callback: string;
  createdTime: number;
}

export interface RunningVncTask {
  display: number;
  userId: string;
  url: string;
  callback: string;
  createdTime: number;
  startTime: number;
  instance: TaskInfo;
  recordingName: string[];
  rtmpUrls: string[];
}

export type ActiveVncTask = PendingVncTask | RunningVncTask;

export function isRunningVncTask(task: ActiveVncTask): task is RunningVncTask {
  return (task as RunningVncTask).instance !== undefined;
}

export function isPendingVncTask(task: ActiveVncTask): task is PendingVncTask {
  return !isRunningVncTask(task);
}
