import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { none, Option, some } from 'ts-option';
import { combineReducers } from 'redux';
import { signIn, signOut} from './actions';
import { UserInfo } from '../models/UserInfo';


export type UserState = Option<UserInfo>;

export interface RootState {
  user: UserState;
}

const savedUser = localStorage.getItem("user");

const loadedUser = savedUser ? some(JSON.parse(savedUser)) : none;

const user = reducerWithInitialState<UserState>(loadedUser)
  .case(signIn, (s, p) => some(p))
  .case(signOut, () => none);

export const appReducer = combineReducers({
  user
});
