import {WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import {UserInfo} from '../models/UserInfo'
import {drawerWidth} from '../variables/style'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {recordUpdate, taskStarted} from '../redux/actions'
import {HttpError} from '../utils/exceptions'
import {RootState} from '../redux/reducers'
import {zukVncClient} from '../client/ZukVncClient'
import {connect} from 'react-redux'
import {RunningVncTask} from '../models/ActiveVncTask'
import {Map} from 'immutable'

const styles = createStyles({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
  },
})

interface ComponentProps {
  user: UserInfo
}

const mapDispatchToProps = {
  taskStarted,
  recordUpdate,
}

type DispatchProps = typeof mapDispatchToProps

type Props = WithStyles<typeof styles> & ComponentProps & DispatchProps

class ZukVncPage extends React.Component<Props> {
  public render() {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>任务编号</TableCell>
            <TableCell>录制</TableCell>
            <TableCell>操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/*{this.props.vnc.map(task => (*/}
          {/*<TableRow key={task.taskId}>*/}
          {/*<TableCell>{task.taskId}</TableCell>*/}
          {/*<TableCell>{task.recording ? '录制中' : '未录制'}</TableCell>*/}
          {/*<TableCell>TODO</TableCell>*/}
          {/*</TableRow>*/}
          {/*))}*/}
        </TableBody>
      </Table>
    )
  }

  // private handleStartTask = async (param: FormData) => {
  //   try {
  //     const id = (await zukVncClient.startRecord(param)).id;
  //     const task: RunningVncTask = {
  //       taskId: id,
  //       url: param.url,
  //       recording: true,
  //       rtmpUrls: []
  //     };
  //     this.props.taskStarted(task);
  //   } catch (e) {
  //     if (e instanceof HttpError) {
  //     }
  //   }
  // };
  //
  // private handleStopRecord = async (id: number) => {
  //   try {
  //     await zukVncClient.stopRecord({display: id});
  //     this.props.recordUpdate({id, recording: false});
  //
  //   } catch (e) {
  //     if (e instanceof HttpError) {
  //     }
  //   }
  // };
}

export default withStyles(styles)(
  connect(
    null,
    mapDispatchToProps,
  )(ZukVncPage),
)
