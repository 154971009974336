import {WithStyles} from '@material-ui/core'
import {StylesProvider, createGenerateClassName} from '@material-ui/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React, {forwardRef} from 'react'
import {UserInfo} from '../../models/UserInfo'
import {formatTimestamp, readableDuration} from '../../utils/time'
import {zukVncClient} from '../../client/ZukVncClient'
import Button from '@material-ui/core/Button/Button'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import ArrowUpward from '@material-ui/icons/ArrowUpward'
import Check from '@material-ui/icons/Check'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Remove from '@material-ui/icons/Remove'
import SaveAlt from '@material-ui/icons/SaveAlt'
import Search from '@material-ui/icons/Search'
import ViewColumn from '@material-ui/icons/ViewColumn'
import {RecordFile} from '../../models/RecordFile'

const styles = createStyles({})

const generateClassName = createGenerateClassName({
  productionPrefix: 'mt',
  seed: 'mt',
})

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Clear: forwardRef((props, ref) => (
    <Clear {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Edit: forwardRef((props, ref) => (
    <Edit {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  SortArrow: forwardRef((props, ref) => (
    <ArrowUpward {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref as React.RefObject<SVGSVGElement>} />
  )) as React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>>,
}

interface ComponentProps {
  user: UserInfo
}

type Props = WithStyles<typeof styles> & ComponentProps

class ZukVncStoppedTaskTab extends React.Component<Props> {
  componentDidMount() {}

  public render() {
    return (
      <StylesProvider generateClassName={generateClassName}>
        <h3>已结束任务浏览</h3>
        <MaterialTable
          options={{
            sorting: false,
            filtering: false,
            search: false,
            toolbar: false,
            pageSize: 20,
            pageSizeOptions: [10, 20, 50],
          }}
          columns={[
            {field: 'taskId', title: '任务编号'},
            {field: 'userId', title: '用户编号'},
            {field: 'createdTime', title: '创建时间'},
            {field: 'duration', title: '持续时长'},
            {
              field: 'record',
              title: '录像',
              render: rowData =>
                rowData.record.map((r: RecordFile) => (
                  <Button
                    key={`${rowData.taskId}-${r.recordName}`}
                    color="primary"
                    onClick={() =>
                      zukVncClient
                        .generateUrl(rowData.taskId, r.recordName, 86400)
                        .then(result => window.open(result.url, '_blank'))
                    }
                  >
                    {r.recordName}
                  </Button>
                )),
            },
          ]}
          data={query => {
            return zukVncClient
              .listAllStoppedTasks(query.pageSize, query.page)
              .then(res => ({
                ...res,
                data: res.data.map(rawData => ({
                  taskId: rawData.task.taskId,
                  userId: rawData.userId,
                  createdTime: formatTimestamp(rawData.task.createdTime),
                  duration: readableDuration(
                    rawData.task.stoppedTime - rawData.task.createdTime,
                  ),
                  record: rawData.task.recordFiles,
                })),
              }))
          }}
          icons={tableIcons}
        />
      </StylesProvider>
    )
  }
}

export default withStyles(styles)(ZukVncStoppedTaskTab)
