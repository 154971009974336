import { HttpError } from './exceptions';
import { store } from '../index';

export const get = (url: string, params?: any) => {
  const realUrl = (params && Object.entries(params).length > 0) ? url + "?" + new URLSearchParams(Object.entries(params)) : url;
  return execute('GET', new Headers(), realUrl);
};

export const del = (url: string) => {
  return execute('DELETE', new Headers(), url);
};

export const post = (url: string, data: {}) => {
  const header = new Headers({'Content-Type': 'application/json'});
  return execute('POST', header, url, data);
};

const execute = (method: string, header: Headers, url: string, data?: {}) => {
  const user = store.getState().user;
  if (user.isDefined) {
    header.append('Authorization', `Bearer ${user.get.token}`);
  }
  let option: RequestInit = {
    headers: new Headers(header),
    method,
  };
  if (data) {
    option = {
      body: JSON.stringify(data),
      ...option
    }
  }

  return fetch(url, option)
    .then(r => {
      switch (r.status) {
        case 200:
        case 201:
        case 202:
          return r.json();
        case 204:
          return {} as any;
        case 400:
        case 401:
        case 403:
        case 404:
        case 409:
          throw new HttpError(r.json(), r.status);
        default:
          throw new Error('Undefined Http Error');
      }
    })
};