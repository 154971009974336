import { GATEWAY_URL, ZUK_VNC_URL } from './profile';

export const SIGN_IN_URL = `${GATEWAY_URL}/tokens`;

export const SIGN_UP_URL = `${GATEWAY_URL}/users`;

export const VALIDATE_TOKEN_URL = (token: string) => `${GATEWAY_URL}/tokens/${token}`;

export const MODULES_URL = `${GATEWAY_URL}/modules`;

export const STOP_TASK_URL = (taskId: number) => `${ZUK_VNC_URL}/tasks/${taskId}`;

export const START_RECORD_URL = `${ZUK_VNC_URL}/record/start`;

export const STOP_RECORD_URL = `${ZUK_VNC_URL}/record/stop`;

export const LIST_ALL_INSTANCE_URL = `${ZUK_VNC_URL}/instances`;

export const LIST_ALL_TASKS_URL = `${ZUK_VNC_URL}/tasks`;

export const LIST_ALL_STOPPED_TASKS_URL = `${ZUK_VNC_URL}/stopped-tasks`;

export const GENERATE_URL_URL = (taskId: number, recordName: string) => `${ZUK_VNC_URL}/stopped-tasks/${taskId}/records/${recordName}/urls`;
