import {WithStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button/Button'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import React from 'react'
import {RouteComponentProps, withRouter} from 'react-router'
import Typography from '@material-ui/core/Typography/Typography'
import {connect} from 'react-redux'
import {signIn} from '../redux/actions'
import Form from '../components/informed/Form'
import {TextField} from '../components/informed/TextField'
import {FormApi} from 'informed'
import {gatewayClient} from '../client/GatewayClient'
import {HttpError} from '../utils/exceptions'

const styles = () =>
  createStyles({
    button: {
      margin: 8,
    },
  })

const mapDispatchToProps = {
  onSignIn: signIn,
}

const initialForm = {
  username: '',
  password: '',
}

type FormData = typeof initialForm

type Props = WithStyles<typeof styles> &
  RouteComponentProps<{}> &
  typeof mapDispatchToProps

class HomePage extends React.Component<Props> {
  private formApi?: FormApi<FormData>

  public render() {
    const classes = this.props.classes
    return (
      <div>
        <Typography variant="h5">登录</Typography>
        <Form
          getApi={formApi => (this.formApi = formApi)}
          initialValues={initialForm}
          onSubmit={this.handleLoginClick}
        >
          <TextField
            field="username"
            label="用户名"
            autoComplete="none"
            fullWidth={true}
            margin="dense"
          />
          <TextField
            field="password"
            type="password"
            label="密码"
            autoComplete="none"
            fullWidth={true}
            margin="dense"
          />
          <Button className={classes.button} onClick={this.handleRegisterClick}>
            注册新用户
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => this.formApi && this.formApi.submitForm()}
          >
            登录
          </Button>
        </Form>
      </div>
    )
  }

  private handleRegisterClick = () => {
    this.props.history.push('/register')
  }

  private handleLoginClick = async (param: FormData) => {
    try {
      const token = await gatewayClient.signIn(param)
      const principal = await gatewayClient.validateToken(`Bearer ${token}`)
      const userInfo = {token, principal}
      this.props.onSignIn(userInfo)
      localStorage.setItem('user', JSON.stringify(userInfo))
    } catch (e) {
      console.error(e)
      if (e instanceof HttpError) {
        const formApi = this.formApi
        formApi && formApi.setError(e.message.field, e.message.error)
      }
    }
  }
}

export default withRouter(
  withStyles(styles)(
    connect(
      null,
      mapDispatchToProps,
    )(HomePage),
  ),
)
