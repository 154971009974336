import React from 'react'
import {UserInfo} from '../../models/UserInfo'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {TaskInfo} from '../../models/TaskInfo'
import {zukVncClient} from '../../client/ZukVncClient'
import {formatTimestamp} from '../../utils/time'

interface ComponentProps {
  user: UserInfo
}

const initState: State = {
  taskInfos: [],
}

interface State {
  taskInfos: TaskInfo[]
}

type Props = ComponentProps

class ZukVncAdminTab extends React.Component<Props, State> {
  readonly state = initState

  componentDidMount() {
    zukVncClient
      .listAllInstances()
      .then(taskInfos => this.setState({taskInfos}))
  }

  public render() {
    return (
      <div>
        <h3>空闲ECS实例信息</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>实例编号</TableCell>
              <TableCell>EC2 ID</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.taskInfos.map(taskInfo => (
              <TableRow key={taskInfo.taskArn}>
                <TableCell>{taskInfo.taskArn}</TableCell>
                <TableCell>{taskInfo.ec2InstanceId}</TableCell>
                <TableCell>{taskInfo.ip}</TableCell>
                <TableCell>{formatTimestamp(taskInfo.createdTime)}</TableCell>
                <TableCell>TODO</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }
}

export default ZukVncAdminTab
