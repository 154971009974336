import actionCreatorFactory from 'typescript-fsa';
import { UserInfo } from '../models/UserInfo';
import { RunningVncTask } from '../models/ActiveVncTask';

const actionCreator = actionCreatorFactory();

export const signIn = actionCreator<UserInfo>('SIGN_IN');

export const signOut = actionCreator<{}>('SIGN_OUT');

export const taskStarted = actionCreator<RunningVncTask>('TASK_STARTED');

export const taskStopped = actionCreator<number>('TASK_STOPPED');

export const recordUpdate = actionCreator<{id: number, recording: boolean}>('RECORD_UPDATE');
