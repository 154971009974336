import { zukVncClientImpl } from './ZukVncClientImpl';
import { TaskInfo } from '../models/TaskInfo';
import { ActiveVncTask, RunningVncTask } from '../models/ActiveVncTask';
import { VncUserInfoUnWindTasks } from '../models/VncUserInfoUnWindTasks';

export interface StartRecordingRequest {
  display: number;
  url: string;
  recordName: string;
  callback: string;
}

export interface ListAllStoppedTasksResponse {
  data: VncUserInfoUnWindTasks[];
  page: number;
  totalCount: number;
}

export interface ZukVncClient {
  stopTask(taskId: number): Promise<{}>

  startRecord(param: StartRecordingRequest): Promise<{id: number}>

  stopRecord(param: {display: number}): Promise<{}>

  listAllInstances(): Promise<TaskInfo[]>

  listAllTasks(): Promise<ActiveVncTask[]>

  listAllStoppedTasks(pageSize: number, pageNum: number): Promise<ListAllStoppedTasksResponse>

  generateUrl(taskId: number, recordName: string, expireTime: number): Promise<{url: string}>

}

export const zukVncClient: ZukVncClient = zukVncClientImpl;