import React from 'react'
import {UserInfo} from '../../models/UserInfo'
import Table from '@material-ui/core/Table/Table'
import TableHead from '@material-ui/core/TableHead/TableHead'
import TableRow from '@material-ui/core/TableRow/TableRow'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableBody from '@material-ui/core/TableBody/TableBody'
import {zukVncClient} from '../../client/ZukVncClient'
import {ActiveVncTask, isRunningVncTask} from '../../models/ActiveVncTask'
import Button from '@material-ui/core/Button/Button'
import {formatTimestamp} from '../../utils/time'
import {List} from 'immutable'

interface ComponentProps {
  user: UserInfo
}

const initState: State = {
  open: false,
  tasks: List.of(),
}

interface State {
  open: boolean
  tasks: List<ActiveVncTask>
}

type Props = ComponentProps

class ZukVncTaskTab extends React.Component<Props, State> {
  readonly state = initState

  componentDidMount() {
    zukVncClient
      .listAllTasks()
      .then(tasks => this.setState({tasks: List(tasks)}))
  }

  onClickClose = (task: ActiveVncTask, index: number) => () => {
    zukVncClient.stopTask(task.display)
    this.setState({tasks: this.state.tasks.splice(index, 1)})
  }

  task2row = (task: ActiveVncTask, index: number) => {
    if (isRunningVncTask(task)) {
      return (
        <TableRow key={task.display}>
          <TableCell>{task.display}</TableCell>
          <TableCell>{task.userId}</TableCell>
          <TableCell>{task.instance.ec2InstanceId}</TableCell>
          <TableCell>{task.instance.ip}</TableCell>
          <TableCell>{formatTimestamp(task.createdTime)}</TableCell>
          <TableCell>运行中</TableCell>
          <TableCell>
            {task.recordingName ? `录制中: ${task.recordingName}` : '未录制'}
          </TableCell>
          <TableCell>
            <Button color="primary" onClick={this.onClickClose(task, index)}>
              关闭
            </Button>
          </TableCell>
        </TableRow>
      )
    } else {
      return (
        <TableRow key={task.display}>
          <TableCell>{task.display}</TableCell>
          <TableCell>{task.userId}</TableCell>
          <TableCell />
          <TableCell />
          <TableCell>{formatTimestamp(task.createdTime)}</TableCell>
          <TableCell>等待中</TableCell>
          <TableCell />
          <TableCell>
            <TableCell>
              <Button
                color="primary"
                onClick={() => {
                  zukVncClient.stopTask(task.display)
                  this.setState({tasks: this.state.tasks.delete(index)})
                }}
              >
                关闭
              </Button>
            </TableCell>
          </TableCell>
        </TableRow>
      )
    }
  }

  public render() {
    return (
      <div>
        <h3>Zukvnc任务信息</h3>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>任务编号</TableCell>
              <TableCell>用户</TableCell>
              <TableCell>EC2 ID</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell>任务状态</TableCell>
              <TableCell>录制状态</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.state.tasks.map(this.task2row)}</TableBody>
        </Table>
      </div>
    )
  }
}

export default ZukVncTaskTab
